export const eventFilters = {
    REGISTERED: 'REGISTERED',
    UNREGISTERED: 'UNREGISTERED',
    DEVICE_METADATA_UPDATED: 'DEVICE_METADATA_UPDATED',
    DEVICE_METADATA_UPDATE_PENDING: 'DEVICE_METADATA_UPDATE_PENDING',
    RESOURCE_PUBLISHED: 'RESOURCE_PUBLISHED',
    RESOURCE_UNPUBLISHED: 'RESOURCE_UNPUBLISHED',
    RESOURCE_UPDATE_PENDING: 'RESOURCE_UPDATE_PENDING',
    RESOURCE_UPDATED: 'RESOURCE_UPDATED',
    RESOURCE_RETRIEVE_PENDING: 'RESOURCE_RETRIEVE_PENDING',
    RESOURCE_RETRIEVED: 'RESOURCE_RETRIEVED',
    RESOURCE_DELETE_PENDING: 'RESOURCE_DELETE_PENDING',
    RESOURCE_DELETED: 'RESOURCE_DELETED',
    RESOURCE_CREATE_PENDING: 'RESOURCE_CREATE_PENDING',
    RESOURCE_CREATED: 'RESOURCE_CREATED',
    RESOURCE_CHANGED: 'RESOURCE_CHANGED',
}
