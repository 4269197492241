// Auto-generated file created by svgr-cli source svg-template.js
// Do not edit directly
import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'
const SvgIconCloudSuccess = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
    <svg xmlns='http://www.w3.org/2000/svg' width={props.width || 16} height={props.height || 16} fill='none' viewBox='0 0 24 16' ref={ref} {...props}>
        <path
            fill='#52C5A2'
            fillRule='evenodd'
            d='M24 9.651c0 1.45-.465 2.785-1.246 3.853v.001c-.954 1.275-2.02 1.787-2.287 1.915l-.037.018c-1.16.56-2.382.582-2.95.554l-.048.001h-.058a678 678 0 0 0-6.006 0c-.875 0-1.94-.005-3.022-.01-1.116-.007-2.25-.013-3.209-.013C2.3 15.97 0 13.56 0 10.586c0-2.973 2.3-5.384 5.137-5.384l.096.002.048.002.001.007.002.006C5.953 2.228 8.514 0 11.57 0c2.064 0 3.9 1.014 5.083 2.592.105.14.276.41.492.773q.394-.057.801-.058C21.29 3.307 24 6.147 24 9.651m-7.765-3.872a.99.99 0 0 1 0 1.349l-4.798 5.092a.86.86 0 0 1-1.272 0L7.763 9.674a.996.996 0 0 1 0-1.349.86.86 0 0 1 1.272 0l1.764 1.87 4.165-4.416a.864.864 0 0 1 1.271 0'
            clipRule='evenodd'
        />
    </svg>
)
const ForwardRef = forwardRef(SvgIconCloudSuccess)
const Memo = memo(ForwardRef)
export default Memo
