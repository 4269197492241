// Auto-generated file created by svgr-cli source svg-template.js
// Do not edit directly
import * as React from 'react'
import type { SVGProps } from 'react'
import { Ref, forwardRef, memo } from 'react'
const SvgIconCertificate = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
    <svg xmlns='http://www.w3.org/2000/svg' width={props.width || 16} height={props.height || 16} fill='none' viewBox='0 0 16 16' ref={ref} {...props}>
        <path
            fill='currentcolor'
            d='M7.495 1.47a.97.97 0 0 1 1.01 0L9 1.776a.97.97 0 0 0 .558.141l.591-.034c.367-.02.711.167.875.474l.267.497c.088.167.233.3.408.383l.536.253a.85.85 0 0 1 .505.82l-.036.555a.82.82 0 0 0 .15.523l.328.464a.815.815 0 0 1 0 .947l-.328.467a.83.83 0 0 0-.15.523l.036.555a.85.85 0 0 1-.505.82l-.53.25a.9.9 0 0 0-.409.383l-.27.502a.93.93 0 0 1-.874.474l-.591-.034a.97.97 0 0 0-.559.14l-.494.308a.97.97 0 0 1-1.01 0L7 10.88a.97.97 0 0 0-.558-.14l-.591.033a.93.93 0 0 1-.875-.474l-.267-.497a.9.9 0 0 0-.408-.383l-.536-.253a.85.85 0 0 1-.505-.82l.036-.554a.82.82 0 0 0-.15-.524l-.325-.466a.815.815 0 0 1 0-.948l.325-.463a.83.83 0 0 0 .15-.524l-.036-.554a.85.85 0 0 1 .505-.82l.53-.25a.9.9 0 0 0 .412-.389l.266-.497a.93.93 0 0 1 .875-.474l.591.034c.198.01.395-.04.559-.14zm2.727 4.857a2.02 2.02 0 0 0-.651-1.473A2.3 2.3 0 0 0 8 4.244c-.59 0-1.154.22-1.57.61s-.651.92-.651 1.473.234 1.083.65 1.473.982.61 1.571.61c.59 0 1.154-.22 1.57-.61s.652-.92.652-1.473m-7.517 6.505 1.196-2.67a.02.02 0 0 1 .011.011l.267.498c.325.604 1 .97 1.722.932l.591-.034q.01-.001.02.005l.494.308q.213.129.447.2l-1.044 2.325a.44.44 0 0 1-.37.253.45.45 0 0 1-.41-.188l-.895-1.283-1.557.216a.47.47 0 0 1-.417-.156.39.39 0 0 1-.058-.417zm6.886 1.573-1.044-2.323q.234-.07.447-.2l.494-.308a.04.04 0 0 1 .02-.005l.591.034c.722.039 1.397-.328 1.722-.932l.267-.498a.02.02 0 0 1 .01-.01l1.2 2.669a.4.4 0 0 1-.058.417.45.45 0 0 1-.417.156l-1.557-.216-.895 1.28a.45.45 0 0 1-.41.188.44.44 0 0 1-.37-.252'
        />
    </svg>
)
const ForwardRef = forwardRef(SvgIconCertificate)
const Memo = memo(ForwardRef)
export default Memo
